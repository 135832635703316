import { initializeApp } from 'firebase/app';
//import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBdNpqfBIVYB69tEd-u6OoLNTCtzl0o0ho",
  authDomain: "gig-gauge.firebaseapp.com",
  projectId: "gig-gauge",
  storageBucket: "gig-gauge.appspot.com",
  messagingSenderId: "340233498127",
  appId: "1:340233498127:web:55af3c4a8a1bee127cfd7a",
  measurementId: "G-XDYT92C5ZP"
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();