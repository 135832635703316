import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import Spinner from './Spinner';
import './FormStyles.css';

export const AddVehicleForm = ({ currentUser, closeForm }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState("gas");
    const [annualInsuranceCost, setAnnualInsuranceCost] = useState();
    const [annualMaintenanceCost, setAnnualMaintenanceCost] = useState();
    const [purchasePrice, setPurchasePrice] = useState();
    const [resaleValue, setResaleValue] = useState();
    const [yearsOfOwnership, setYearsOfOwnership] = useState();
    const [annualMilage, setAnnualMilage] = useState();
    const [otherAnnualCosts, setOtherAnnualCosts] = useState();
    const [MPkWh, setMPkWh] = useState();
    const [MPG, setMPG] = useState();
    const [electricOnlyRange, setElectricOnlyRange] = useState();
    const [electricMilageUnits, setElectricMilageUnits] = useState("milesPerkWh");
    const [includeInsuranceCosts, setIncludeInsuranceCosts] = useState(false);
    const [includeDepreciationCosts, setIncludeDepreciationCosts] = useState(false);
    const [includeOtherAnnualCosts, setIncludeOtherAnnualCosts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [headerTitle, setHeaderTitle] = useState();
    const [formIsValid, setFormIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const calculateValues = () => {
        let gas;
        let electric;
        let annualCost = 0;
        let depreciation;
        let mileCost;

        //Set usesGas and usesElectricity based on vehicle type
        if (type === 'gas' || type === 'hybrid') {
            gas = true;
        } else {
            gas = false;
        }

        if (type === 'electric' || type === 'hybrid') {
            electric = true;
        } else {
            electric = false;
        }

        if (includeDepreciationCosts) {
            depreciation = (purchasePrice - resaleValue) / yearsOfOwnership;
            annualCost += annualCost + depreciation;
        } else {
            depreciation = 0;
        }

        if (includeInsuranceCosts) {
            annualCost += annualCost + annualInsuranceCost;
        }

        if (includeOtherAnnualCosts) {
            annualCost += annualCost + annualMaintenanceCost + otherAnnualCosts;
        }

        if (includeDepreciationCosts || includeInsuranceCosts || includeOtherAnnualCosts) {
            mileCost = annualCost / annualMilage;
        }

        return { gas, electric, annualCost, depreciation, mileCost };
    };

    const handleAddVehicle = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { gas, electric, annualCost, depreciation, mileCost } = calculateValues();

        try {
            await addDoc(collection(db, 'vehicles'), {
                name,
                type,
                costToDriveAMile: mileCost ?? 0,
                annualInsuranceCost: annualInsuranceCost ?? 0,
                annualMaintenanceCost: annualMaintenanceCost ?? 0,
                purchasePrice: purchasePrice ?? 0,
                resaleValue: resaleValue ?? 0,
                yearsOfOwnership: yearsOfOwnership ?? 0,
                annualMilage: annualMilage ?? 0,
                otherAnnualCosts: otherAnnualCosts ?? 0,
                annualDepreciation: depreciation ?? 0,
                totalAnnualCost: annualCost ?? 0,
                usesGas: gas ?? false,
                usesElectricity: electric ??false,
                MPkWh: type === 'electric' || type === 'hybrid' ? MPkWh : 0,
                MPG: type === 'gas' || type === 'hybrid' ? MPG : 0,
                electricOnlyRange: type === 'hybrid' ? electricOnlyRange : 0,
                includeInsuranceCosts,
                includeDepreciationCosts,
                includeOtherAnnualCosts,
                userId: currentUser.uid,
            });
            closeForm();
        } catch (error) {
            console.error('Error adding vehicle: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const validateForm = () => { 
            const nameIsSet = name.length > 0;
            let fuelEfficiencyIsSet = true;
            let milesDrivenIsSet = true;
            let insuranceIsSet = true;
            let purchasePriceIsSet = true;
            let yearsOwnedIsSet = true;
            let resalePriceIsSet = true;
            let maintenanceIsSet = true;
            let otherCostsIsSet = true;

            //Check fuel efficiency
            if (type === 'non-powered') {
                fuelEfficiencyIsSet = true;
            } else if (type === 'gas') {
                MPG > 0 ? fuelEfficiencyIsSet = true : fuelEfficiencyIsSet = false;
            } else if (type === 'electric') {
                MPkWh > 0 ? fuelEfficiencyIsSet = true : fuelEfficiencyIsSet = false;
            } else if (type === 'hybrid') {
                ((electricOnlyRange >= 0) && (MPG >= 0) && (MPkWh >= 0)) ? fuelEfficiencyIsSet = true : fuelEfficiencyIsSet = false;
            }

            //Check miles driven
            if (includeInsuranceCosts || includeDepreciationCosts || includeOtherAnnualCosts) {
                annualMilage > 0 ? milesDrivenIsSet = true : milesDrivenIsSet = false;
            }

            //Check insurance fields
            if (includeInsuranceCosts) {
                annualInsuranceCost > 0 ? insuranceIsSet = true : insuranceIsSet = false;
            }

            //Check depreciation fields
            if (includeDepreciationCosts) {
                purchasePrice >= 0 ? purchasePriceIsSet = true : purchasePriceIsSet = false;
                yearsOfOwnership >= 0 ? yearsOwnedIsSet = true : yearsOwnedIsSet = false;
                resaleValue >= 0 ? resalePriceIsSet = true : resalePriceIsSet = false;
            }

            //Check other cost fields
            if (includeOtherAnnualCosts) {
                annualMaintenanceCost >= 0 ? maintenanceIsSet = true : maintenanceIsSet = false;
                otherAnnualCosts >= 0 ? otherCostsIsSet = true : otherCostsIsSet = false;
            }

            //Final check of all fields with error messages
            if (nameIsSet && fuelEfficiencyIsSet && milesDrivenIsSet && insuranceIsSet && purchasePriceIsSet && yearsOwnedIsSet && resalePriceIsSet && maintenanceIsSet && otherCostsIsSet) {
                setFormIsValid(true);
                setErrorMessage('');
            } else {
                setFormIsValid(false);
                if (!nameIsSet) {
                    setErrorMessage('Enter a name for your vehicle');
                } else if (!fuelEfficiencyIsSet) {
                    setErrorMessage('Ensure your fuel efficiency is properly set');
                } else if (!milesDrivenIsSet) {
                    setErrorMessage('Miles driven must be greater than 0');
                } else if (!insuranceIsSet) {
                    setErrorMessage('Insurance cost must be greater than $0');
                } else if (!purchasePriceIsSet) {
                    setErrorMessage('Purchase price must be at least $0');
                } else if (!yearsOwnedIsSet) {
                    setErrorMessage('Estimated years owned must be greater than 0');
                } else if (!resalePriceIsSet) {
                    setErrorMessage("Estimated resale value must be at least $0");
                } else if (!maintenanceIsSet) {
                    setErrorMessage("Maintenance cost must be at least $0");
                } else if (!otherCostsIsSet) {
                    setErrorMessage('Other costs must be at least $0')
                } else {
                    setErrorMessage("You messed something up!")
                }
            }
        };

        validateForm();
    }, [MPG, MPkWh, annualInsuranceCost, annualMaintenanceCost, annualMilage, electricOnlyRange, includeDepreciationCosts, includeInsuranceCosts, includeOtherAnnualCosts, name.length, otherAnnualCosts, purchasePrice, resaleValue, type, yearsOfOwnership]);

    const goToNextStep = () => {
        setCurrentStep(prev => prev + 1);
    };

    const goToPreviousStep = () => {
        setCurrentStep(prev => prev - 1);
    };

    useEffect(() => {
        const headerTitles = ["Vehicle Info","Optional Stats", ];
        const steps = document.querySelectorAll('.form-step');
        steps.forEach((step, index) => {
            step.classList.toggle('active', index === currentStep);
        });
        setHeaderTitle(headerTitles[currentStep]);
    }, [currentStep]);

    return (
        <div className="form-overlay" onClick={closeForm}>
            <div className="form-container" onClick={e => e.stopPropagation()}>
                {loading && <Spinner />}
                <div className='form-header'>
                    <h2>{headerTitle}</h2>
                    <button className='close-form-button' onClick={closeForm}>x</button>
                </div>
                <form onSubmit={handleAddVehicle}>
                    <div className='step-container'>
                        <div className='form-step active'>
                            <label>
                                Vehicle Name:
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                            </label>
                            <label>
                                What fuel do you put in your vehicle?
                                <select value={type} onChange={(e) => setType(e.target.value)} disabled={loading}>
                                    <option value="gas">Only gas</option>
                                    <option value="electric">Only electricity</option>
                                    <option value="hybrid">Gas and electricity</option>
                                    <option value="non-powered">None</option>
                                </select>
                            </label>
                            {type !== 'non-powered' && (
                                <p className='italicized-text'>If you do not know a value for your fuel efficiency, it can probably be found on fueleconomy.gov</p>
                            )}
                            {type === 'gas' && (
                                <label>
                                    Fuel Efficiency (MPG):
                                    <input
                                        type="number"
                                        min="0"
                                        max="200"
                                        step="0.1"
                                        value={MPG}
                                        onChange={(e) => setMPG(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {type === 'electric' && (
                                <>
                                    <label>
                                        Milage Units:
                                        <select value={electricMilageUnits} onChange={(e) => setElectricMilageUnits(e.target.value)} disabled={loading}>
                                            <option value="milesPerkWh">Miles per kWh</option>
                                            <option value="kWhPer100Miles">kWh per 100 miles</option>
                                            <option value="MPGe">MPGe</option>
                                        </select>
                                    </label>

                                    {electricMilageUnits === 'milesPerkWh' && (
                                        <label>
                                            MPkWh:
                                            <input 
                                                type="number" 
                                                min="0"
                                                max="20"
                                                step="0.1"
                                                value={MPkWh} 
                                                onChange={(e) => setMPkWh(e.target.value)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}

                                    {electricMilageUnits === 'kWhPer100Miles' && (
                                        <label>
                                            kWh/100 Miles:
                                            <input 
                                                type="number" 
                                                min="0"
                                                max="100"
                                                step="0.1"
                                                value={100 / MPkWh || ""}
                                                onChange={(e) => setMPkWh(100 / e.target.value)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}

                                    {electricMilageUnits === 'MPGe' && (
                                        <label>
                                            MPGe:
                                            <input 
                                                type="number" 
                                                min="0"
                                                max="300"
                                                step="0.1"
                                                value={MPkWh * 33.7 || ""} 
                                                onChange={(e) => setMPkWh(e.target.value / 33.7)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}
                                </>
                            )}
                            {type === 'hybrid' && (
                                <>
                                    <label>
                                    Electric Range (Miles):
                                        <input
                                            type="number"
                                            min="0"
                                            max="500"
                                            step="0.1"
                                            value={electricOnlyRange}
                                            onChange={(e) => setElectricOnlyRange(e.target.value)}
                                            disabled={loading}
                                            required
                                        />
                                    </label>
                                    <p className='bolded-text'>When on electric power, what is your fuel efficiency?</p>
                                    <label>
                                        Milage Units:
                                        <select value={electricMilageUnits} onChange={(e) => setElectricMilageUnits(e.target.value)} disabled={loading}>
                                            <option value="milesPerkWh">Miles per kWh</option>
                                            <option value="kWhPer100Miles">kWh per 100 miles</option>
                                            <option value="MPGe">MPGe</option>
                                        </select>
                                    </label>

                                    {electricMilageUnits === 'milesPerkWh' && (
                                        <label>
                                            MPkWh:
                                            <input 
                                                type="number"
                                                min="0"
                                                max="20"
                                                step="0.1"
                                                value={MPkWh} 
                                                onChange={(e) => setMPkWh(e.target.value)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}

                                    {electricMilageUnits === 'kWhPer100Miles' && (
                                        <label>
                                            kWh/100 Miles:
                                            <input 
                                                type="number" 
                                                min="0"
                                                max="100"
                                                step="0.1"
                                                value={100 / MPkWh || ""}
                                                onChange={(e) => setMPkWh(100 / e.target.value)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}

                                    {electricMilageUnits === 'MPGe' && (
                                        <label>
                                            MPGe:
                                            <input 
                                                type="number" 
                                                min="0"
                                                max="300"
                                                step="0.1"
                                                value={MPkWh * 33.7 || ""} 
                                                onChange={(e) => setMPkWh(e.target.value / 33.7)} 
                                                disabled={loading}
                                                required
                                            />
                                        </label>
                                    )}

                                    <p className='bolded-text'>What is your fuel efficiency when running on gas?</p>

                                    <label>
                                        Fuel Efficiency (MPG):
                                        <input
                                            type="number"
                                            min="0"
                                            max="100"
                                            step="0.1"
                                            value={MPG}
                                            onChange={(e) => setMPG(e.target.value)}
                                            disabled={loading}
                                            required
                                        />
                                    </label>

                                </>
                            )}
                            <div className="buttons">
                                <button type="button" onClick={goToNextStep}>Next</button>
                            </div>
                        </div>
                        <div className='form-step'>
                            <div className='checkboxes'>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={includeInsuranceCosts}
                                        onChange={(e) => setIncludeInsuranceCosts(e.target.checked)}
                                        disabled={loading}
                                    />
                                    <span>Include insurance costs</span>
                                </label>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={includeDepreciationCosts}
                                        onChange={(e) => setIncludeDepreciationCosts(e.target.checked)}
                                        disabled={loading}
                                    />
                                    <span>Include vehicle depreciation costs</span>
                                </label>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={includeOtherAnnualCosts}
                                        onChange={(e) => setIncludeOtherAnnualCosts(e.target.checked)}
                                        disabled={loading}
                                    />
                                    <span>Include other annual costs (maintenance, taxes, etc.)</span>
                                </label>
                            </div>
                            {(includeDepreciationCosts || includeInsuranceCosts || includeOtherAnnualCosts) && (
                                <label>
                                    Estimated miles driven per year:
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000"
                                        step="1"
                                        value={annualMilage}
                                        onChange={(e) => setAnnualMilage(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeInsuranceCosts && (
                                <label>
                                    Insurance cost per year:
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000"
                                        step="0.01"
                                        value={annualInsuranceCost}
                                        onChange={(e) => setAnnualInsuranceCost(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeDepreciationCosts && (
                                <label>
                                    Vehicle purchase price:
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000000"
                                        step="0.01"
                                        value={purchasePrice}
                                        onChange={(e) => setPurchasePrice(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeDepreciationCosts && (
                                <label>
                                    Estimated total years of ownership:
                                    <input
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="1"
                                        value={yearsOfOwnership}
                                        onChange={(e) => setYearsOfOwnership(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeDepreciationCosts && (
                                <label>
                                    Estimated vehicle value when sold:
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000000"
                                        step="0.01"
                                        value={resaleValue}
                                        onChange={(e) => setResaleValue(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeOtherAnnualCosts && (
                                <label>
                                    Approximate annual maintenance costs (oil changes, tire changes, etc.):
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000"
                                        step="0.01"
                                        value={annualMaintenanceCost}
                                        onChange={(e) => setAnnualMaintenanceCost(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {includeOtherAnnualCosts && (
                                <label>
                                    Other annual costs (registration fees, taxes, etc.):
                                    <input
                                        type="number"
                                        min="0"
                                        max="100000"
                                        step="0.01"
                                        value={otherAnnualCosts}
                                        onChange={(e) => setOtherAnnualCosts(e.target.value)}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}

                            {errorMessage && <p className='error-message'>{errorMessage}</p>}

                            <div className='buttons'>
                                <button type="button" onClick={goToPreviousStep}>Back</button>
                                <button type="submit" disabled={!formIsValid}>Add Vehicle</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};