import React, { useEffect, useState } from 'react';
import './Apps.css';

const Apps = ({ currentUser, apps, shifts, vehicles }) => {
    const [appsStats, setAppsStats] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentUser && apps && shifts && vehicles) {
            const calculateGasCost = (shift, selectedVehicleData) => {
                if (!selectedVehicleData) return 0;
                if (selectedVehicleData.type === 'non-powered') {
                    return 0;
                } else if (selectedVehicleData.type === 'gas') {
                    return (shift.milesDriven / selectedVehicleData.MPG) * shift.gasPrice;
                } else if (selectedVehicleData.type === 'electric') {
                    return (shift.milesDriven / selectedVehicleData.MPkWh) * shift.electricityPrice;
                } else if (selectedVehicleData.type === 'hybrid') {
                    const electricMiles = Math.min(shift.milesDriven, selectedVehicleData.electricOnlyRange);
                    const totalElectricityUsed = electricMiles / selectedVehicleData.MPkWh;
                    const totalElectricityCost = totalElectricityUsed * shift.electricityPrice;

                    const gasMiles = Math.max(0, shift.milesDriven - selectedVehicleData.electricOnlyRange);
                    const totalGasUsed = gasMiles / selectedVehicleData.MPG;
                    const totalGasCost = totalGasUsed * shift.gasPrice;

                    return totalElectricityCost + totalGasCost;
                }
            }

            const fetchData = async () => {
                const appStats = apps.map(app => {
                    const appShifts = shifts.filter(shift => shift.appWorkedForId === app.id);
                    if (appShifts.length === 0) {
                        return {
                            appName: app.name,
                            lastShiftDate: '-',
                            totalRevenue: '0.00',
                            totalRevenueMinusGas: '0.00',
                            totalRevenueMinusAllAddCosts: '0.00',
                            activeTime: '0',
                            totalTime: '0',
                            numDeliveries: '0',
                            numShifts: '0',
                            milesDriven: '0',
                        };
                    }

                    const lastShiftDate = new Date(Math.max(...appShifts.map(shift => new Date(shift.shiftStart))));
                    const appStats = appShifts.reduce((acc, shift) => {
                        const selectedVehicleData = vehicles.find(vehicle => vehicle.id === shift.vehicleDrivenId);
                        const revenue = parseInt(shift.basePay) + parseInt(shift.tips) + parseInt(shift.additionalPay);
                        const gas = calculateGasCost(shift, selectedVehicleData);
                        const additionalCosts = (selectedVehicleData.costToDriveAMile * shift.milesDriven);
                        const total = revenue - gas - additionalCosts;

                        acc.totalEarnings += revenue;
                        acc.totalAfterGas += revenue - gas;
                        acc.totalAfterAll += total;
                        acc.totalHours += parseFloat(shift.totalTimeMinutes) / 60;
                        acc.activeHours += parseFloat(shift.activeTimeMinutes) / 60;
                        acc.numDeliveries += parseFloat(shift.numDeliveries);
                        acc.numShifts += 1;
                        acc.milesDriven += parseFloat(shift.milesDriven);

                        return acc;
                    }, { totalEarnings: 0, totalAfterGas: 0, totalAfterAll: 0, totalHours: 0, activeHours: 0, numDeliveries: 0, numShifts: 0, milesDriven: 0 });

                    return {
                        appName: app.name,
                        lastShiftDate: lastShiftDate.toLocaleDateString(),
                        totalRevenue: appStats.totalEarnings.toFixed(2),
                        totalRevenueMinusGas: appStats.totalAfterGas.toFixed(2),
                        totalRevenueMinusAllAddCosts: appStats.totalAfterAll.toFixed(2),
                        //TODO: Show time in hours and minutes
                        activeTime: appStats.activeHours.toFixed(2),
                        totalTime: appStats.totalHours.toFixed(2),
                        numDeliveries: appStats.numDeliveries.toFixed(0),
                        numShifts: appStats.numShifts.toFixed(0),
                        milesDriven: appStats.milesDriven.toFixed(1)
                    };
                });

                setAppsStats(appStats)
            };

            fetchData();
        }
    }, [currentUser, apps, shifts, vehicles]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? appsStats.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === appsStats.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="apps-card">
            <h2>Apps</h2>
            <div className="carousel-wrapper">
                <div className="carousel-container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {appsStats.length > 0 ? (
                        appsStats.map((appStat, index) => (
                            <div key={index} className="app-stat">
                                <div className="app-stat-titles">
                                    <div className="app-name">{appStat.appName}</div>
                                    <div className="last-shift">Last Shift: {appStat.lastShiftDate}</div>
                                </div>
                                <div className='app-stat-details'>
                                    <div className='app-stat-row'>
                                        <span>Total Revenue:</span>
                                        <span><span className='app-stat-money'>${appStat.totalRevenue}</span><span className='sub-text'> (${(appStat.totalRevenue / appStat.milesDriven) ? (appStat.totalRevenue / appStat.milesDriven).toFixed(2) : '0.00'}/mile)</span></span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Total Money After Gas:</span>
                                        <span><span className='app-stat-money'>${appStat.totalRevenueMinusGas}</span><span className='sub-text'> (${(appStat.totalRevenueMinusGas / appStat.milesDriven) ? (appStat.totalRevenueMinusGas / appStat.milesDriven).toFixed(2) : '0.00'}/mile)</span></span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Total Money After All Costs:</span>
                                        <span><span className='app-stat-money'>${appStat.totalRevenueMinusAllAddCosts}</span><span className='sub-text'> (${(appStat.totalRevenueMinusAllAddCosts / appStat.milesDriven) ? (appStat.totalRevenueMinusAllAddCosts / appStat.milesDriven).toFixed(2) : '0.00'}/mile)</span></span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Active Time:</span>
                                        <span><span className='app-stat-number'>{parseInt(appStat.activeTime)}</span><span className='sub-text'> hrs</span><span className='app-stat-number'> {((parseFloat(appStat.activeTime) - parseInt(appStat.activeTime)) * 60).toFixed(0)}</span><span className='sub-text'> mins</span></span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Total Time:</span>
                                        <span><span className='app-stat-number'>{parseInt(appStat.totalTime)}</span><span className='sub-text'> hrs</span><span className='app-stat-number'> {((parseFloat(appStat.totalTime) - parseInt(appStat.totalTime)) * 60).toFixed(0)}</span><span className='sub-text'> mins</span></span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Number of Deliveries:</span>
                                        <span className='app-stat-number'>{appStat.numDeliveries}</span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Number of Shifts:</span>
                                        <span className='app-stat-number'>{appStat.numShifts}</span>
                                    </div>
                                    <div className='app-stat-row'>
                                        <span>Miles Driven:</span>
                                        <span className='app-stat-number'>{appStat.milesDriven}</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No app data available</div>
                    )}
                </div>
            </div>
            <div className="carousel-control left" onClick={handlePrev}></div>
            <div className="carousel-control right" onClick={handleNext}></div>
            <div className="carousel-dots">
                {appsStats.map((_, index) => (
                    <span key={index} className={`dot ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)}></span>
                ))}
            </div>
        </div>
    );
};

export default Apps;