import React, { useEffect, useState } from 'react';
import './RecentActivity.css';

const RecentActivity = ({ currentUser, apps, vehicles, shifts }) => {
    const [lastShiftStats, setLastShiftStats] = useState({});
    const [thisMonthStats, setThisMonthStats] = useState({});

    useEffect(() => {
        if (currentUser && apps && vehicles) {
            const calculateGasCost = (shift, selectedVehicleData) => {
                if (!selectedVehicleData) return 0;
                if (selectedVehicleData.type === 'non-powered') {
                    return 0;
                } else if (selectedVehicleData.type === 'gas') {
                    return (shift.milesDriven / selectedVehicleData.MPG) * shift.gasPrice;
                } else if (selectedVehicleData.type === 'electric') {
                    return (shift.milesDriven / selectedVehicleData.MPkWh) * shift.electricityPrice;
                } else if (selectedVehicleData.type === 'hybrid') {
                    const electricMiles = Math.min(shift.milesDriven, selectedVehicleData.electricOnlyRange);
                    const totalElectricityUsed = electricMiles / selectedVehicleData.MPkWh;
                    const totalElectricityCost = totalElectricityUsed * shift.electricityPrice;

                    const gasMiles = Math.max(0, shift.milesDriven - selectedVehicleData.electricOnlyRange);
                    const totalGasUsed = gasMiles / selectedVehicleData.MPG;
                    const totalGasCost = totalGasUsed * shift.gasPrice;

                    return totalElectricityCost + totalGasCost;
                }
            }

            const calculateStats = (shifts) => {
                if (!shifts.length) return {};
        
                const sortedShifts = shifts.sort((a, b) => new Date(b.shiftStart) - new Date(a.shiftStart));
                const lastShift = sortedShifts[0];
        
                const currentMonth = new Date().getMonth();
                const thisMonthShifts = sortedShifts.filter((shift) => new Date(shift.shiftStart).getMonth() === currentMonth);
        
                const thisMonthStats = thisMonthShifts.reduce((acc, shift) => {
                    let selectedVehicleData = vehicles.find(vehicle => vehicle.id === shift.vehicleDrivenId);
                    if (!selectedVehicleData) return acc;
                    acc.revenue += parseFloat(shift.basePay) + parseFloat(shift.tips) + parseFloat(shift.additionalPay);
                    acc.gas += calculateGasCost(shift, selectedVehicleData);
                    acc.additionalCosts += (selectedVehicleData.costToDriveAMile * shift.milesDriven);
                    acc.total = acc.revenue - acc.gas - acc.additionalCosts;
                    return acc;
                }, { revenue: 0, gas: 0, additionalCosts: 0, total: 0 });
        
                const lastShiftStats = () => {
                    const selectedVehicleData = vehicles.find(vehicle => vehicle.id === lastShift.vehicleDrivenId);
                    if (!selectedVehicleData) return { revenue: 0, gas: 0, additionalCosts: 0, total: 0 };
        
                    const revenue = parseFloat(lastShift.basePay) + parseFloat(lastShift.tips) + parseFloat(lastShift.additionalPay);
                    const gas = calculateGasCost(lastShift, selectedVehicleData);
                    const additionalCosts = (selectedVehicleData.costToDriveAMile * lastShift.milesDriven);
                    const total = revenue - gas - additionalCosts;
                    return { revenue, gas, additionalCosts, total };
                };
        
                return { lastShiftStats, thisMonthStats };
            };

            const { lastShiftStats, thisMonthStats } = calculateStats(shifts);
            setLastShiftStats(lastShiftStats);
            setThisMonthStats(thisMonthStats);
        }
    }, [currentUser, apps, vehicles, shifts]);

    return (
        <div className="recent-activity-card">
            <h2>Recent Activity</h2>
            <div className='activity-sections'>
            <div className="activity-section">
                    <h3>This Month</h3>
                    <div className="activity-stats">
                        <div className="stat-item">
                            <div className="stat-title">Revenue:</div>
                            <div className="stat-value highlight">${thisMonthStats?.revenue >= 0 ? parseFloat(thisMonthStats.revenue).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Gas:</div>
                            <div className="stat-value negative-highlight">-${thisMonthStats?.gas >= 0 ? parseFloat(thisMonthStats.gas).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Add. Costs:</div>
                            <div className="stat-value negative-highlight">-${thisMonthStats?.additionalCosts >= 0 ? parseFloat(thisMonthStats.additionalCosts).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item total-stat">
                            <div className="stat-title">Total:</div>
                            <div className="stat-value highlight">${thisMonthStats?.total >= 0 ? parseFloat(thisMonthStats.total).toFixed(2) : '-'}</div>
                        </div>
                    </div>
                </div>
                <div className='activity-section'>
                    <h3>Last Shift</h3>
                    <div className='activity-stats'>
                        <div className='stat-item'>
                            <div className='stat-title'>Revenue:</div>
                            <div className="stat-value highlight">${lastShiftStats?.revenue >= 0 ? parseFloat(lastShiftStats.revenue).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Gas:</div>
                            <div className="stat-value negative-highlight">-${lastShiftStats?.gas >= 0 ? parseFloat(lastShiftStats.gas).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-title">Add. Costs:</div>
                            <div className="stat-value negative-highlight">-${lastShiftStats?.additionalCosts >= 0 ? parseFloat(lastShiftStats.additionalCosts).toFixed(2) : '-'}</div>
                        </div>
                        <div className="stat-item total-stat">
                            <div className="stat-title">Total:</div>
                            <div className="stat-value highlight">${lastShiftStats?.total >= 0 ? parseFloat(lastShiftStats.total).toFixed(2) : '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentActivity;