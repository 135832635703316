import { db } from './firebase-config';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';

export const createShift = async (shiftData) => {
    try {
        const docRef = await addDoc(collection(db, 'shifts'), shiftData);
        console.log('Document written with ID: ', docRef.id);
    } catch (e) {
        console.error('Error adding document: ', e);
    }
};

export const getShiftsForUser = async (userId) => {
    const shiftsRef = collection(db, 'shifts');
    const q = query(shiftsRef, where ('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const shifts = querySnapshot.docs.map((doc) => doc.data());
    return shifts;
};

export const getAppsForUser = async (userId) => {
    const appsQuery = query(collection(db, 'apps'), where('userId', '==', userId));
    const appsSnapshot = await getDocs(appsQuery);
    const apps = appsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return apps;
};

export const getVehiclesForUser = async (userId) => {
    const vehiclesQuery = query(collection(db, 'vehicles'), where('userId', '==', userId));
    const vehiclesSnapshot = await getDocs(vehiclesQuery);
    const vehicles = vehiclesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return vehicles;
};