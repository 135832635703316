import React, { useEffect, useState } from 'react';
import './Summary.css';

const Summary = ({ currentUser, apps, vehicles, shifts }) => {
    const [lifetimeStats, setLifetimeStats] = useState({});

    useEffect(() => {
        if (currentUser && apps && vehicles) {
            const calculateGasCost = (shift, selectedVehicleData) => {
                if (!selectedVehicleData) return 0;
                if (selectedVehicleData.type === 'non-powered') {
                    return 0;
                } else if (selectedVehicleData.type === 'gas') {
                    return (shift.milesDriven / selectedVehicleData.MPG) * shift.gasPrice;
                } else if (selectedVehicleData.type === 'electric') {
                    return (shift.milesDriven / selectedVehicleData.MPkWh) * shift.electricityPrice;
                } else if (selectedVehicleData.type === 'hybrid') {
                    const electricMiles = Math.min(shift.milesDriven, selectedVehicleData.electricOnlyRange);
                    const totalElectricityUsed = electricMiles / selectedVehicleData.MPkWh;
                    const totalElectricityCost = totalElectricityUsed * shift.electricityPrice;

                    const gasMiles = Math.max(0, shift.milesDriven - selectedVehicleData.electricOnlyRange);
                    const totalGasUsed = gasMiles / selectedVehicleData.MPG;
                    const totalGasCost = totalGasUsed * shift.gasPrice;

                    return totalElectricityCost + totalGasCost;
                }
            }

            const calculateStats = (shifts) => {
                if (!shifts.length) return {};
        
                const lifetimeStats = shifts.reduce((acc, shift) => {
                    let selectedVehicleData = vehicles.find(vehicle => vehicle.id === shift.vehicleDrivenId);
                    if (!selectedVehicleData) return acc;
                    acc.revenue += parseFloat(shift.basePay) + parseFloat(shift.tips) + parseFloat(shift.additionalPay);
                    acc.gas += calculateGasCost(shift, selectedVehicleData);
                    acc.additionalCosts += (selectedVehicleData.costToDriveAMile * shift.milesDriven);
                    acc.total = acc.revenue - acc.gas - acc.additionalCosts;
                    acc.numDeliveries += parseFloat(shift.numDeliveries);
                    acc.milesDriven += parseFloat(shift.milesDriven)
                    return acc;
                }, { revenue: 0, gas: 0, additionalCosts: 0, total: 0, numDeliveries: 0, milesDriven: 0 });
        
                return { lifetimeStats };
            };

            const { lifetimeStats } = calculateStats(shifts);
            setLifetimeStats(lifetimeStats);
        }
    }, [currentUser, apps, vehicles, shifts]);

    return (
        <div className="summary-card">
            <h2>Summary</h2>
            <div className='summary-money-stats'>
                <div className='money-stats-titles'>
                    <div>Total Revenue:</div>
                    <div>Total Gas Costs:</div>
                    <div>Total Additional Costs:</div>
                </div>
                <div className='money-stats'>
                    <div><span className='highlight'>${lifetimeStats?.revenue >= 0 ? parseFloat(lifetimeStats.revenue).toFixed(2) : '0.00'}</span></div>
                    <div><span className='negative-highlight'>{lifetimeStats?.gas >= 0 ? '-' : ''}${lifetimeStats?.gas >= 0 ? parseFloat(lifetimeStats.gas).toFixed(2) : '0.00'}</span></div>
                    <div><span className='negative-highlight'>{lifetimeStats?.additionalCosts >= 0 ? '-' : ''}${lifetimeStats?.additionalCosts >= 0 ? parseFloat(lifetimeStats.additionalCosts).toFixed(2) : '0.00'}</span></div>
                </div>
            </div>
            <div className='summary-money-stats'>
                <div className='total-money-stat-title'>
                    <div className='bold'>Total Profit:</div>
                </div>
                <div className='total-money-stat'>
                    <div className='bold'><span className='highlight'>${lifetimeStats?.total >= 0 ? parseFloat(lifetimeStats.total).toFixed(2) : '0.00'}</span></div>
                </div>
            </div>
            <div className='summary-other-stats'>
                <div className='other-stats-titles'>
                    <div>Lifetime Deliveries:</div>
                    <div>Lifetime Milage:</div>
                </div>
                <div className='other-stats'>
                    <div className='bold'>{lifetimeStats?.numDeliveries >= 0 ? parseFloat(lifetimeStats.numDeliveries).toFixed(0) : '0'}</div>
                    <div className='bold'>{lifetimeStats?.milesDriven >= 0 ? parseFloat(lifetimeStats.milesDriven).toFixed(0) : '0'} miles</div>
                </div>
            </div>
        </div>
    );
};

export default Summary;