import { auth, googleProvider } from "../../config/firebase-config";
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import './Auth.css';

export const Auth = () => {
  const navigate = useNavigate();
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [username, setUsername] =useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(null);

  const errorMessages = {
    'auth/invalid-email': 'Invalid email address.',
    'auth/user-disabled': 'User account is disabled',
    'auth/user-not-found': 'No user found with this email',
    'auth/wrong-password': 'Incorrect password',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/weak-password': 'Password should be at least 6 characters',
    'auth/operation-not-allowed': 'Operation not allowed',
  };

  const getErrorMessage = (errorCode) => {
    return errorMessages[errorCode] || "An unknown error occured. Please try again.";
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/overview');
    } catch (error) {
      setError("Google sign-in failed. Please try again.");
    }
  };

  const signInWithEmailOrUsername = async (e) => {
    e.preventDefault();
    try {
      let email = emailOrUsername;
      if (!emailOrUsername.includes('@')) {
        const userDoc = await getDoc(doc(db, "usernames", emailOrUsername));
        if (userDoc.exists()) {
          email = userDoc.data().email;
        } else {
          throw new Error("Username not found.");
        }
      }
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/overview');
    } catch (error) {
      if (error.message === "Username not found.") {
        setError("Email/Username not found.");
      } else {
        setError(getErrorMessage(error.code));
      }
    }
  };

  const registerWithEmail = async (e) => {
    e.preventDefault();
    try {
      const userDoc = await getDoc(doc(db, "usernames", username));

      if (userDoc.exists()) {
        throw new Error("Username already exists.")
      }

      await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
      await setDoc(doc(db, "usernames", username), { email: registerEmail, username });
      navigate('./overview');
    } catch (error) {
      if (error.message === "Username already exists.") {
        setError("Username already exists.");
      } else {
        setError(getErrorMessage(error.code));
      }
    }
  };

  return (
    <div className="auth-container">
      {!isRegistering && (
        <div className="auth-form-container">
          <h1>Sign In</h1>
          <form onSubmit={signInWithEmailOrUsername}>
            <input
              type="text"
              placeholder="Email or Username"
              value={emailOrUsername}
              onChange={(e) => setEmailOrUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" className="submit-button">Sign in</button>
          </form>
          <div className="social-login">
            <button className="google-button" onClick={signInWithGoogle}>
              <img src={`${process.env.PUBLIC_URL}/google-logo.png`} alt="Google logo" className="google-logo" />
              Continue with Google
            </button>
          </div>
          <button 
            onClick={() => setIsRegistering(true)}
            className="register-link"
          >
            Create an account
          </button>
          <button 
            onClick={() => navigate('/forgot-password')}
            className="forgot-password-link"
          >
            Forgot password?
          </button>
        </div>
      )}
      {isRegistering && (
        <div className="auth-form-container">
          <h1>Register</h1>
          <form onSubmit={registerWithEmail}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
            />
            <button type="submit" className="submit-button">Register</button>
          </form>
          <button 
            onClick={() => setIsRegistering(false)}
            className="register-link"
          >
            Already have an account? Log In
          </button>
        </div>
      )}
      {error && <p className="auth-error-message">{error}</p>}
    </div>
  );
};
