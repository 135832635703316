import React, { useState, useRef, useEffect } from "react";
import './HeaderDropdownMenu.css';

const HeaderMenu = ({ onLogout, onRefresh }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);

    const handleMenuToggle = () => {
        setMenuVisible(!menuVisible);
    };

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (menuVisible) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        };
    }, [menuVisible]);

    return (
        <div className="header-menu" ref={menuRef}>
            <button className="menu-button" onClick={onRefresh}>↻</button>
            <button className="menu-button" onClick={handleMenuToggle}>⋮</button>
            {menuVisible && (
                <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={onLogout}>Log Out</button>
                    {/* More buttons here */}
                </div>
            )}
        </div>
    );
};

export default HeaderMenu