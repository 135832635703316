import React, { useState } from 'react';
import { auth } from "../../config/firebase-config";
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your inbox.');
            setError(null);
        } catch (error) {
            setError('Failed to send password reset email. Please try again.');
            setMessage(null);
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="forgot-password-form-container">
                <h1>Forgot Password</h1>
                <form onSubmit={handlePasswordReset}>
                    <input
                        type="email"
                        placeholder='Enter your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit" className='submit-button'>Send Password Reset Email</button>
                </form>
                {message && <p className="message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                <button onClick={() => navigate('/')} className='back-link'>Back to Login</button>
            </div>
        </div>
    );
};