import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Auth } from "./pages/auth/Auth";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";
import { Overview } from "./pages/overview/Overview";
import { AuthProvider, useAuth } from "./hooks/AuthProvider";
import { auth, db } from "./config/firebase-config";
import { useEffect, useState } from "react";
import { getVehiclesForUser, getAppsForUser, getShiftsForUser } from "./config/firestore";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import Spinner from "./components/Spinner";

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [apps, setApps] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        fetchUserData(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const appsQuery = query(collection(db, 'apps'), where('userId', '==', currentUser.uid));
      const vehiclesQuery = query(collection(db, 'vehicles'), where('userId', '==', currentUser.uid));
      const shiftsQuery = query(collection(db, 'shifts'), where('userId', '==', currentUser.uid));

      const unsubscribeApps = onSnapshot(appsQuery, (snapshot) => {
        const updatedApps = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setApps(updatedApps);
      });

      const unsubscribeVehicles = onSnapshot(vehiclesQuery, (snapshot) => {
        const updatedVehicles = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setVehicles(updatedVehicles);
      });

      const unsubscribeShifts = onSnapshot(shiftsQuery, (snapshot) => {
        const updatedShifts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setShifts(updatedShifts);
      });

      return () => {
        unsubscribeApps();
        unsubscribeVehicles();
        unsubscribeShifts();
      };
    }
  }, [currentUser]);

  const fetchUserData = async (uid) => {
    try {
      const userApps = await getAppsForUser(uid);
      const userVehicles = await getVehiclesForUser(uid);
      const userShifts = await getShiftsForUser(uid);
      setApps(userApps);
      setVehicles(userVehicles);
      setShifts(userShifts);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      console.log('User logged out');
    }).catch((error) => {
      console.error('Error logging out: ', error);
    });
  };

  const handleRefresh = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 750)
  };

  return (
  <div className="App"> 
    {loading && <Spinner />}
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" exact element={<Auth />} />
          <Route path="/forgot-password" exact element={<ForgotPassword/>} />
          <Route path="/overview" element={<ProtectedRoute><Overview currentUser={currentUser} apps={apps} vehicles={vehicles} shifts={shifts} onLogout={handleLogout} onRefresh={handleRefresh}/></ProtectedRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  </div>
  );
}

export default App;
