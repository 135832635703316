import React, { useState } from 'react';
import RecentActivity from '../../components/RecentActivity';
import Summary from '../../components/Summary';
import Apps from '../../components/Apps';
import EarningsPerAppCard from '../../components/EarningsPerApp';
import Header from '../../components/Header';
import FloatingActionButton from '../../components/FloatingActionButton';
import { AddAppForm } from '../../components/AddAppForm';
import { AddVehicleForm } from '../../components/AddVehicleForm';
import { AddShiftForm } from '../../components/AddShiftForm';
import './Overview.css';

export const Overview = ({ currentUser, apps, vehicles, shifts, onLogout, onRefresh }) => {
    const [showAddAppForm, setShowAddAppForm] = useState(false);
    const [showAddVehicleForm, setShowAddVehicleForm] = useState(false);
    const [showAddShiftForm, setShowAddShiftForm] = useState(false);

    const openAddAppForm = () => setShowAddAppForm(true);
    const closeAddAppForm = () => setShowAddAppForm(false);
    const openAddVehicleForm = () => setShowAddVehicleForm(true);
    const closeAddVehicleForm = () => setShowAddVehicleForm(false);
    const openAddShiftForm = () => setShowAddShiftForm(true);
    const closeAddShiftForm = () => setShowAddShiftForm(false);

    return (
        <div className="overview-page">
            <Header onRefresh={onRefresh} onLogout={onLogout}/>
            <div className='overview-cards'>
                <div className='card'><RecentActivity currentUser={currentUser} apps={apps} shifts={shifts} vehicles={vehicles} /></div>
                <div className='card'><Summary currentUser={currentUser} apps={apps} shifts={shifts} vehicles={vehicles} /></div>
                <div className='card'><Apps currentUser={currentUser} apps={apps} shifts={shifts} vehicles={vehicles} /></div>
                <div className='card'><EarningsPerAppCard currentUser={currentUser} apps={apps} shifts={shifts} vehicles={vehicles} /></div>
            </div>
            <FloatingActionButton openAddAppForm={openAddAppForm} openAddVehicleForm={openAddVehicleForm} openAddShiftForm={openAddShiftForm}/>
            {showAddAppForm && <AddAppForm currentUser={currentUser} closeForm={closeAddAppForm} />}
            {showAddVehicleForm && <AddVehicleForm currentUser={currentUser} closeForm={closeAddVehicleForm} />}
            {showAddShiftForm && <AddShiftForm currentUser={currentUser} apps={apps} vehicles={vehicles} closeForm={closeAddShiftForm} />}
        </div>
    );
};