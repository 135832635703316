import React, { useState } from "react";
import './FloatingActionButton.css';

const FloatingActionButton = ({ openAddAppForm, openAddVehicleForm, openAddShiftForm }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="fab-container">
            {isOpen && (
                <div className="fab-menu">
                    <button className="fab-menu-item" onClick={openAddShiftForm}>Add a Shift</button>
                    <button className="fab-menu-item" onClick={openAddVehicleForm}>Add a Vehicle</button>
                    <button className="fab-menu-item" onClick={openAddAppForm}>Add an App</button>
                </div>
            )}
            <button className="fab-button" onClick={toggleMenu}>
                {isOpen ? '×' : '+'}
            </button>
        </div>
    );
};

export default FloatingActionButton;