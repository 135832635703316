import { useEffect, useRef } from "react";
import { 
    Chart,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import './EarningsPerApp.css';

Chart.register(
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

const EarningsPerAppCard = ({ currentUser, apps, vehicles, shifts }) => {

    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (currentUser && apps && shifts && vehicles) {
            const calculateGasCost = (shift, selectedVehicleData) => {
                if (!selectedVehicleData) return 0;
                if (selectedVehicleData.type === 'non-powered') {
                    return 0;
                } else if (selectedVehicleData.type === 'gas') {
                    return (shift.milesDriven / selectedVehicleData.MPG) * shift.gasPrice;
                } else if (selectedVehicleData.type === 'electric') {
                    return (shift.milesDriven / selectedVehicleData.MPkWh) * shift.electricityPrice;
                } else if (selectedVehicleData.type === 'hybrid') {
                    const electricMiles = Math.min(shift.milesDriven, selectedVehicleData.electricOnlyRange);
                    const totalElectricityUsed = electricMiles / selectedVehicleData.MPkWh;
                    const totalElectricityCost = totalElectricityUsed * shift.electricityPrice;

                    const gasMiles = Math.max(0, shift.milesDriven - selectedVehicleData.electricOnlyRange);
                    const totalGasUsed = gasMiles / selectedVehicleData.MPG;
                    const totalGasCost = totalGasUsed * shift.gasPrice;

                    return totalElectricityCost + totalGasCost;
                }
            }

            const calculateEarningsPerApp = () => {
                return apps.map(app => {
                    const appShifts = shifts.filter(shift => shift.appWorkedForId === app.id);

                    const appStats = appShifts.reduce((acc, shift) => {
                        const selectedVehicleData = vehicles.find(vehicle => vehicle.id === shift.vehicleDrivenId);
                        const revenue = parseInt(shift.basePay) + parseInt(shift.tips) + parseInt(shift.additionalPay);
                        const gas = calculateGasCost(shift, selectedVehicleData);
                        const additionalCosts = (selectedVehicleData.costToDriveAMile * shift.milesDriven);
                        const total = revenue - gas - additionalCosts;

                        acc.totalEarnings += revenue;
                        acc.totalAfterGas += revenue - gas;
                        acc.totalAfterAll += total;
                        acc.totalHours += shift.totalTimeMinutes / 60;
                        if (app.hasShiftActiveTime || app.hasDeliveryActiveTime) {
                            acc.totalActiveHours += shift.activeTimeMinutes / 60;
                        }

                        return acc;
                    }, { totalEarnings: 0, totalAfterGas: 0, totalAfterAll: 0, totalHours: 0, totalActiveHours: 0 });

                    const earnings = {
                        app: app.name,
                        avgEarningsPerHour: appStats.totalHours ? appStats.totalEarnings / appStats.totalHours : 0,
                        avgEarningsPerHourAfterGas: appStats.totalHours ? appStats.totalAfterGas / appStats.totalHours : 0,
                        avgEarningsPerHourAfterAll: appStats.totalHours ? appStats.totalAfterAll / appStats.totalHours : 0,
                    };

                    if (app.hasShiftActiveTime || app.hasDeliveryActiveTime) {
                        earnings.avgEarningsPerActiveHour = appStats.totalActiveHours ? appStats.totalEarnings / appStats.totalActiveHours : 0;
                        earnings.avgEarningsPerActiveHourAfterGas = appStats.totalActiveHours ? appStats.totalAfterGas / appStats.totalActiveHours : 0;
                        earnings.avgEarningsPerActiveHourAfterAll = appStats.totalActiveHours ? appStats.totalAfterAll / appStats.totalActiveHours : 0;
                    }

                    return earnings;
                });
            }

            const earningsData = calculateEarningsPerApp();

            if (chartRef.current) {
                const ctx = chartRef.current.getContext('2d');

                if (chartInstanceRef.current) {
                    chartInstanceRef.current.destroy();
                }

                chartInstanceRef.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: earningsData.map(data => data.app),
                        datasets: [
                            {
                                label: 'Money / hr',
                                data: earningsData.map(data => data.avgEarningsPerHour),
                                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 1,
                                stack: 'Earnings'
                            },
                            {
                                label: 'Money / active hr',
                                data: earningsData.map(data => data.avgEarningsPerActiveHour || 0),
                                backgroundColor: 'rgba(75, 192, 192, 0.3)',
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 1,
                                stack: 'Earnings'
                            },
                            {
                                label: 'Money / hr after gas',
                                data: earningsData.map(data => data.avgEarningsPerHourAfterGas),
                                backgroundColor: 'rgba(153, 102, 255, 0.5)',
                                borderColor: 'rgba(153, 102, 255, 1)',
                                borderWidth: 1,
                                stack: 'Earnings After Gas'
                            },
                            {
                                label: 'Money / active hr after gas',
                                data: earningsData.map(data => data.avgEarningsPerActiveHourAfterGas || 0),
                                backgroundColor: 'rgba(153, 102, 255, 0.3)',
                                borderColor: 'rgba(153, 102, 255, 1)',
                                borderWidth: 1,
                                stack: 'Earnings After Gas'
                            },
                            {
                                label: 'Money / hr after all costs',
                                data: earningsData.map(data => data.avgEarningsPerHourAfterAll),
                                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                                borderColor: 'rgba(255, 159, 64, 1)',
                                borderWidth: 1,
                                stack: 'Earnings After All'
                            },
                            {
                                label: 'Money / active hr after all costs',
                                data: earningsData.map(data => data.avgEarningsPerActiveHourAfterAll || 0),
                                backgroundColor: 'rgba(255, 159, 64, 0.3)',
                                borderColor: 'rgba(255, 159, 64, 1)',
                                borderWidth: 1,
                                stack: 'Earnings After All'
                            }
                        ],
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true,
                                stacked: false,
                                ticks: {
                                    callback: function(value, index, ticks) {
                                        return '$' + value;
                                    }
                                },
                                title: {
                                    display: true,
                                    text: "Average $/hr"
                                }
                            },
                            x: {
                                stacked: true
                            }
                        },
                        plugins: {
                            legend: {
                                position: 'top',
                                align: 'center',
                                labels: {
                                    boxWidth: 20,
                                    boxHeight: 15,
                                    padding: 15,
                                    font: {
                                        size: 12
                                    },
                                    generateLabels: function(chart) {
                                        const datasets = chart.data.datasets;
                                        const perHour = datasets.filter((dataset, index) => index % 2 === 0); // per hour datasets
                                        const perActiveHour = datasets.filter((dataset, index) => index % 2 !== 0); // per active hour datasets
                        
                                        const perHourLabels = perHour.map((dataset, index) => ({
                                            text: dataset.label,
                                            fillStyle: dataset.backgroundColor,
                                            strokeStyle: dataset.borderColor,
                                            lineWidth: dataset.borderWidth,
                                            hidden: !chart.isDatasetVisible(index),
                                            datasetIndex: index
                                        }));
                        
                                        const perActiveHourLabels = perActiveHour.map((dataset, index) => ({
                                            text: dataset.label,
                                            fillStyle: dataset.backgroundColor,
                                            strokeStyle: dataset.borderColor,
                                            lineWidth: dataset.borderWidth,
                                            hidden: !chart.isDatasetVisible(index * 2 + 1),
                                            datasetIndex: index * 2 + 1

                                        }));
                        
                                        return perHourLabels.concat(perActiveHourLabels);
                                    }
                                }
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        let label = context.dataset.label || '';
                        
                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.raw !== null) {
                                            label += '$' + context.raw.toFixed(2);
                                        }
                                        return label;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }
    }, [currentUser, apps, shifts, vehicles]);

    return (
        <div className="earnings-per-app-card">
            <h2>Earnings Per App</h2>
            <canvas ref={chartRef} id="earningsChart"></canvas>
        </div>
    );
};

export default EarningsPerAppCard;
