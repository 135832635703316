import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import Spinner from './Spinner';
import './FormStyles.css';

export const AddAppForm = ({ currentUser, closeForm }) => {
    const [name, setName] = useState('');
    const [hasDeliveryActiveTime, setHasDeliveryActiveTime] = useState(false);
    const [hasShiftActiveTime, setHasShiftActiveTime] = useState(false);
    const [hasEarnByMethod, setHasEarnByMethod] = useState(false);
    const [hasRewardTiers, setHasRewardTiers] = useState(false);
    const [rewardTiers, setRewardTiers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const headerTitle = "Add App";

    const handleAddApp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await addDoc(collection(db, 'apps'), {
                name,
                hasDeliveryActiveTime,
                hasShiftActiveTime,
                hasEarnByMethod,
                hasRewardTiers,
                rewardTiers,
                userId: currentUser.uid,
            });
            closeForm();
        } catch (error) {
            console.error('Error adding app: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const validateForm = () => { 
            const nameIsSet = name.length > 0;
            let rewardTiersIsSet = true;

            //Check insurance fields
            if (hasRewardTiers) {
                rewardTiers[0]?.length > 0 ? rewardTiersIsSet = true : rewardTiersIsSet = false;
                console.log(rewardTiers.length);
            }

            //Final check of all fields with error messages
            if (nameIsSet && rewardTiersIsSet) {
                setFormIsValid(true);
                setErrorMessage('');
            } else {
                setFormIsValid(false);
                if (!nameIsSet) {
                    setErrorMessage('Enter a name for this app');
                } else if (!rewardTiersIsSet) {
                    setErrorMessage('Enter all reward tiers');
                } else {
                    setErrorMessage("You messed something up!")
                }
            }
        };

        validateForm();
    }, [hasRewardTiers, name.length, rewardTiers]);


    return (
        <div className="form-overlay" onClick={closeForm}>
            <div className="form-container" onClick={e => e.stopPropagation()}>
                {loading && <Spinner />}
                <div className='form-header'>
                    <h2>{headerTitle}</h2>
                    <button className='close-form-button' onClick={closeForm}>x</button>
                </div>
                <form onSubmit={handleAddApp}>
                    <div className='step-container'>
                        <div className='form-step active'>
                            <label>
                                App Name:
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                            </label>
                            <div className='checkboxes'>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={hasDeliveryActiveTime}
                                        onChange={(e) => setHasDeliveryActiveTime(e.target.checked)}
                                        disabled={loading}
                                    />
                                    Reports active time for individual deliveries
                                </label>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={hasShiftActiveTime}
                                        onChange={(e) => setHasShiftActiveTime(e.target.checked)}
                                        disabled={loading}
                                    />
                                    Reports active time for shifts
                                </label>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={hasEarnByMethod}
                                        onChange={(e) => setHasEarnByMethod(e.target.checked)}
                                        disabled={loading}
                                    />
                                    Can choose between earning by time, or by offer
                                </label>
                                <label className='checkbox-label'>
                                    <input
                                        type="checkbox"
                                        checked={hasRewardTiers}
                                        onChange={(e) => setHasRewardTiers(e.target.checked)}
                                        disabled={loading}
                                    />
                                    Has Reward Tiers:
                                </label>
                            </div>
                            {hasRewardTiers && (
                                <label>
                                    List all reward tiers, separated by commas:
                                    <input
                                        type="text"
                                        value={rewardTiers}
                                        onChange={(e) => setRewardTiers(e.target.value.split(','))}
                                        disabled={loading}
                                        required
                                    />
                                </label>
                            )}
                            {errorMessage && <p className='error-message'>{errorMessage}</p>}

                            <div className='buttons'>
                                <button type="submit" disabled={!formIsValid}>Add App</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};