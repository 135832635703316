import React from "react";
import HeaderMenu from "./HeaderDropdownMenu";

const Header = ({ onRefresh, onLogout }) => {
    return (
        <div className="header-bar">
            <div className="left-section">
                <button className="menu-button">☰</button>
                <h1>Overview</h1>
            </div>
            <div className="right-section">
                <HeaderMenu onLogout={onLogout} onRefresh={onRefresh}/>
            </div>
        </div>
    );
};

export default Header;