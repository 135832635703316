import React, { useRef, useEffect, useState } from 'react';
import { db } from '../config/firebase-config';
import { collection, addDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import Spinner from './Spinner';
import axios from 'axios';
import './FormStyles.css';

export const AddShiftForm = ({ currentUser, apps, vehicles, closeForm }) => {
    const [rewardTierOptions, setRewardTierOptions] = useState([]);
    const [selectedApp, setSelectedApp] = useState('');
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [holidays, setHolidays] = useState([]);
    const [activeHours, setActiveHours] = useState();
    const [activeMinutes, setActiveMinutes] = useState();
    const [formIsValid, setFormIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [headerTitle, setHeaderTitle] = useState();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        shiftStart: '',
        shiftEnd: '',
        totalTimeMinutes: 0,
        activeTimeMinutes: 0,
        zipCode: '',
        gasPrice: 0,
        electricityPrice: 0,
        tips: 0,
        basePay: 0,
        additionalPay: 0,
        dayOfWeek: '',
        numDeliveries: 0,
        milesDriven: 0,
        weather: [],
        earnByMethod: '',
        isSignificantDay: false,
        appWorkedForId: '',
        locationName: '',
        rewardTier: '',
        vehicleDrivenId: '',
        additionalNotes: '',
        deliveryIds: [],
    })

    const prevShiftStartRef = useRef();

    useEffect(() => {
        prevShiftStartRef.current = formData.shiftStart;
    });

    const prevShiftStart = prevShiftStartRef.current;

    useEffect(() => {
        const fetchLastSelection = async () => {
            if (currentUser) {
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();

                    if (userData.lastSelectedApp) {
                        setSelectedApp(userData.lastSelectedApp);
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            appWorkedForId: userData.lastSelectedApp,
                        }));
                        const selectedAppData = apps.find(app => app.id === userData.lastSelectedApp);
                        if (selectedAppData?.hasRewardTiers) {
                            setRewardTierOptions(selectedAppData.rewardTiers);
                        } else {
                            setRewardTierOptions([]);
                        }
                    }
                    if (userData.lastSelectedVehicle) {
                        setSelectedVehicle(userData.lastSelectedVehicle);
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            vehicleDrivenId: userData.lastSelectedVehicle,
                        }));
                    }
                    if (userData.lastSelectedRewardTiers && userData.lastSelectedRewardTiers[userData.lastSelectedApp]) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            rewardTier: userData.lastSelectedRewardTiers[userData.lastSelectedApp],
                        }));
                    }
                    if (userData.lastSelectedEarnByMethods && userData.lastSelectedEarnByMethods[userData.lastSelectedApp]) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            earnByMethod: userData.lastSelectedEarnByMethods[userData.lastSelectedApp],
                        }));    
                    }
                    if (userData.previousGasPrice) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            gasPrice: userData.previousGasPrice,
                        }));    
                    }
                    if (userData.previousElectricityPrice) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            electricityPrice: userData.previousElectricityPrice,
                        }));    
                    }
                    if (userData.previousZipCode) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            zipCode: userData.previousZipCode,
                        }));    
                    }
                }
            }
        };

        fetchLastSelection();

        if (apps.length === 1) {
            const onlyApp = apps[0];
            setSelectedApp(onlyApp.id);
            setFormData(prevFormData => ({
                ...prevFormData,
                appWorkedForId: onlyApp.id,
            }));
        }

        if (vehicles.length === 1) {
            const onlyVehicle = vehicles[0];
            setSelectedVehicle(onlyVehicle.id);
            setFormData(prevFormData => ({
                ...prevFormData,
                vehicleDrivenId: onlyVehicle.id,
            }));
        }

        const today = new Date();
        setFormData(prevFormData => ({
            ...prevFormData,
            shiftStart: today.toISOString().slice(0,11).concat("09:00"),
            shiftEnd: today.toISOString().slice(0,11).concat("17:00"),
        }));
    }, [apps, vehicles, currentUser]);    
    
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const countryCode = 'US';

        const fetchData = async () => {
            const holidayData = await fetchHolidays(currentYear, countryCode);
            setHolidays(holidayData);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (formData.shiftStart) {
            const date = new Date(formData.shiftStart);
            setFormData((prevFormData) => ({
                ...prevFormData,
                isSignificantDay: isHoliday(date, holidays),
            }));
        }
    }, [formData.shiftStart, holidays]);
    
    const fetchLocationDataFromZip = async (zipCode) => {
        const apiKey = '96f9202906fb4ba4a930530534f8a07f';
        const url = `https://api.opencagedata.com/geocode/v1/json?q=${zipCode}&key=${apiKey}&countrycode=ca,us`;

        try {
            const response = await axios.get(url);
            const result = response.data.results[0];
            const { lat, lng } = result.geometry;
            const timezone = result.annotations.timezone.name;
            const city = result.components._normalized_city || result.components.city || result.components.town || result.components.village || result.components.state;
            return { latitude: lat, longitude: lng, timezone, city };
        } catch (error) {
            console.error('Error fetching coordinates and timezone:', error);
            return null;
        }
    };

    //TODO: In the future, add a fetchHistoricalWeatherData for requests more than 3 months in the past
    const fetchWeatherData = async (latitude, longitude, date, timezone) => {
        const params = {
            latitude,
            longitude,
            hourly: ["temperature_2m", "rain", "showers", "snowfall", "snow_depth", "visibility"],
            temperature_unit: "fahrenheit",
            wind_speed_unit: "mph",
            precipitation_unit: "inch",
            timezone,
            start_date: date,
            end_date: date
        };

        const url = "https://api.open-meteo.com/v1/forecast";
        try {
            const response = await axios.get(url, { params });           
            const hourly = response.data.hourly;

            if (!hourly) {
                throw new Error("No hourly weather data found");
            }

            const weatherData = {
                temps: hourly.temperature_2m,
                rain: hourly.rain,
                showers: hourly.showers,
                snowfall: hourly.snowfall,
                snowDepth: hourly.snow_depth,
                visibility: hourly.visibility,
            };

            return weatherData;
        } catch (error) {
            console.error("Error fetching weather data:", error);
            return null;
        }
    };

    const fetchHolidays = async (year, countryCode) => {
        try {
            const response = await fetch(`https://date.nager.at/api/v3/PublicHolidays/${year}/${countryCode}`);
            if (!response.ok) {
                throw new Error('Failed to fetch holiday data');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching holiday data:', error);
            return [];
        }
    };

    const isHoliday = (date, holidays) => {
        const dateString = date.toISOString().split('T')[0];
        return holidays.some(holiday => holiday.date === dateString);
    };

    useEffect(() => {
        if (prevShiftStart !== formData.shiftStart) {
            const start = formData.shiftStart;
            const end = formData.shiftEnd;

            let endTime = end.split('T')[1];
            
            //I don't know why this bug happens now, but this fixes it
            //Something to do with it not seeing formData.shiftEnd update on form load
            if (endTime === "undefined") {
                endTime = "17:00";
            }

            const newEnd = start.split('T')[0].concat('T').concat(endTime);
            setFormData(prevFormData => ({
                ...prevFormData,
                shiftEnd: newEnd,
            }));
        }
    }, [formData.shiftStart, formData.shiftEnd, prevShiftStart]);

    const calculateValues = () => {
        const start = new Date(formData.shiftStart);
        const end = new Date (formData.shiftEnd);

        const timeDifference = end.getTime() - start.getTime();
        const minutesElapsed = Math.floor(timeDifference / (1000 * 60));

        const dayOfWeekNumber = start.getDay();

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeekName = daysOfWeek[dayOfWeekNumber];

        let activeTime = 60 * parseInt(activeHours || 0) + parseInt(activeMinutes || 0);

        const selectedAppData = apps.find(app => app.id === formData.appWorkedForId);

        if (!selectedAppData?.hasShiftActiveTime) {
            activeTime = 0;
        }

        return { minutesElapsed, dayOfWeekName, activeTime };
    };
    
    const handleAppChange = (event) => {
        const appId = event.target.value;
        setSelectedApp(appId);
        setFormData(prevFormData => ({
            ...prevFormData,
            appWorkedForId: appId,
            rewardTier: '',
        }));

        const selectedAppData = apps.find(app => app.id === appId);
        if (selectedAppData?.hasRewardTiers) {
            setRewardTierOptions(selectedAppData.rewardTiers);
            if (currentUser) {
                getDoc(doc(db, 'users', currentUser.uid)).then((userDoc) => {
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        if (userData.lastSelectedRewardTiers && userData.lastSelectedRewardTiers[appId]) {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                rewardTier: userData.lastSelectedRewardTiers[appId],
                            }));
                        }
                        if (userData.lastSelectedEarnByMethods && userData.lastSelectedEarnByMethods[appId]) {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                earnByMethod: userData.lastSelectedEarnByMethods[appId],
                            }));
                        }
                    }
                });
            }
        } else {
            setRewardTierOptions([]);
            setFormData(prevFormData => ({
                ...prevFormData,
                earnByMethod: '',
            }));
        }
    };

    const handleVehicleChange = (event) => {
        const vehicleId = event.target.value;
        setSelectedVehicle(vehicleId);
        setFormData(prevFormData => ({
            ...prevFormData,
            vehicleDrivenId: vehicleId
        }));
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    useEffect(() => {
        const validateForm = () => { 
            const appIsSelected = selectedApp !== '';
            const vehicleIsSelected = selectedVehicle !== '';
            const isValidZipCode = /^\d{5}(-\d{4})?$/.test(formData.zipCode);
            const startTime = new Date(formData.shiftStart);
            const endTime = new Date(formData.shiftEnd);
            const activeTime = (60 * parseInt(activeHours || 0) + parseInt(activeMinutes || 0));
            const totalTime = Math.floor((endTime.getTime() - startTime.getTime()) / (1000 * 60));
            let activeTimeIsSet = true;
            const selectedAppData = apps.find(app => app.id === formData.appWorkedForId);
            let rewardTierIsSet = true;
            const gasPriceIsSet = formData.gasPrice >= 0;
            const milesDrivenIsSet = formData.milesDriven >= 0;
            const numDeliveriesIsSet = formData.numDeliveries >= 0;
            const basePayIsSet = formData.basePay >= 0;
            const tipsIsSet = formData.tips >= 0;
            const additionalPayIsSet = formData.additionalPay >= 0;

            //Check reward tier selection
            if (appIsSelected && selectedAppData.hasRewardTiers) {
                formData.rewardTier === '' ? rewardTierIsSet = false : rewardTierIsSet = true;
            }

            if (appIsSelected && selectedAppData.hasShiftActiveTime) {
                if (activeTime >= 0 && activeTime <= totalTime) {
                    activeTimeIsSet = true;
                } else {
                    activeTimeIsSet = false;
                }
            }
            
    
            if (appIsSelected && vehicleIsSelected && startTime < endTime && isValidZipCode && activeTimeIsSet && rewardTierIsSet && gasPriceIsSet && milesDrivenIsSet && numDeliveriesIsSet && basePayIsSet && tipsIsSet && additionalPayIsSet) {
                setFormIsValid(true);
                setErrorMessage('');
            } else {
                setFormIsValid(false);
                if (!appIsSelected) {
                    setErrorMessage('Select an app');
                } else if (!vehicleIsSelected) {
                    setErrorMessage('Select a vehicle');
                } else if (startTime >= endTime) {
                    setErrorMessage('Start time must be before end time.');
                } else if (!isValidZipCode) {
                    setErrorMessage('Invalid zip code.');
                } else if (!activeTimeIsSet) {
                    setErrorMessage('Ensure active time and shift start/end are correctly set');
                } else if (!rewardTierIsSet) {
                    setErrorMessage('Select a reward tier');
                } else if (!gasPriceIsSet) {
                    setErrorMessage('Gas price must be at least $0');
                } else if (!milesDrivenIsSet) {
                    setErrorMessage('Miles driven must be at least 0');
                } else if (!numDeliveriesIsSet) {
                    setErrorMessage('Number of deliveries must be at least 0');
                } else if (!basePayIsSet) {
                    setErrorMessage('Base pay must be at least $0');
                } else if (!tipsIsSet) {
                    setErrorMessage('Tips must be at least $0');
                } else if (!additionalPayIsSet) {
                    setErrorMessage('Additional pay must be at least $0');
                } else {
                    setErrorMessage('You did something wrong');
                }
            }
        };

        validateForm();
    }, [selectedApp, selectedVehicle, activeHours, activeMinutes, formData, apps]);

    const handleAddShift = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (currentUser && formIsValid) {
            const { zipCode } = formData;
            const locationData = await fetchLocationDataFromZip(zipCode);
           
            if (locationData) {
                const { latitude, longitude, timezone, city } = locationData;
                const { minutesElapsed, dayOfWeekName, activeTime } = calculateValues();

                const startDateTime = new Date(formData.shiftStart);
                const date = startDateTime.toISOString().split('T')[0];

                const weatherData = await fetchWeatherData(latitude, longitude, date, timezone);

                if (weatherData) {
                    const shiftData = {
                        ...formData,
                        userId: currentUser.uid,
                        weather: weatherData,
                        locationName: city,
                        totalTimeMinutes: minutesElapsed,
                        dayOfWeek: dayOfWeekName,
                        activeTimeMinutes: activeTime,
                    };

                    try {
                        await addDoc(collection(db, 'shifts'), shiftData);
                    } catch (error) {
                        console.error('Error adding shift: ', error);
                    }

                    try {
                        await setDoc(doc(db, 'users', currentUser.uid), {
                            lastSelectedApp: selectedApp,
                            lastSelectedVehicle: selectedVehicle,
                            previousGasPrice: formData.gasPrice,
                            previousElectricityPrice: formData.electricityPrice,
                            previousZipCode: formData.zipCode,
                            lastSelectedRewardTiers: {
                                [formData.appWorkedForId]: formData.rewardTier,
                            },
                            lastSelectedEarnByMethods: {
                                [formData.appWorkedForId]: formData.earnByMethod,
                            }
                        }, { merge: true });
                        closeForm();
                    } catch (error) {
                        console.error('Error setting user data: ', error);
                    } finally {
                        setLoading(false);
                    }
                }
            }            
        }
    };

    const goToNextStep = () => {
        setCurrentStep(prev => prev + 1);
    };

    const goToPreviousStep = () => {
        setCurrentStep(prev => prev - 1);
    };

    useEffect(() => {
        const headerTitles = ["App and Vehicle Info", "Time Info", "Shift Info", "Financial Info"];
        const steps = document.querySelectorAll('.form-step');
        steps.forEach((step, index) => {
            step.classList.toggle('active', index === currentStep);
        });
        setHeaderTitle(headerTitles[currentStep]);
    }, [currentStep]);

    return (
        <div className="form-overlay" onClick={closeForm}>
            <div className="form-container" onClick={e => e.stopPropagation()}>
                {loading && <Spinner />}
                <div className='form-header'>
                    <h2>{headerTitle}</h2>
                    <button className='close-form-button' onClick={closeForm}>x</button>
                </div>
                <form onSubmit={handleAddShift}>
                    <div className='step-container'>
                        <div className='form-step active'>
                            <label>
                                Select App:
                                <select value={selectedApp} onChange={handleAppChange} disabled={loading} required>
                                    <option value="">Select App</option>
                                    {apps.map(app => (
                                        <option key={app.id} value={app.id}>{app.name}</option>
                                    ))}
                                </select>
                            </label>

                            <label>
                                Select Vehicle:
                                <select value={selectedVehicle} onChange={handleVehicleChange} disabled={loading} required>
                                    <option value="">Select Vehicle</option>
                                    {vehicles.map(vehicle => (
                                        <option key={vehicle.id} value={vehicle.id}>{vehicle.name}</option>
                                    ))}
                                </select>
                            </label>

                            {apps.find(app => app.id === selectedApp)?.hasEarnByMethod && (
                                <label>
                                    Earn By:
                                    <select name="earnByMethod" value={formData.earnByMethod} onChange={handleInputChange} disabled={loading} required>
                                        <option value='offer'>Offer</option>
                                        <option value='time'>Time</option>
                                    </select>
                                </label>
                            )}

                            {rewardTierOptions.length > 0 && (
                                <label>
                                    Reward Tier:
                                    <select name='rewardTier' value={formData.rewardTier} onChange={handleInputChange}disabled={loading} >
                                        <option value=''>Select Reward Tier</option>
                                        <option value='none'>None</option>
                                        {rewardTierOptions.map((tier, index) => (
                                            <option key={index} value={tier}>{tier}</option>
                                        ))}
                                    </select>
                                </label>
                            )}
                            <div className="buttons">
                                <button type="button" onClick={goToNextStep}>Next</button>
                            </div>
                        </div>
                        <div className='form-step'>
                            <label>
                                Shift Start:
                                <input type="datetime-local" name="shiftStart" value={formData.shiftStart} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <label>
                                Shift End:
                                <input type="datetime-local" name="shiftEnd" value={formData.shiftEnd} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <div className='checkboxes'>
                                <label className='checkbox-label'>
                                    <input type="checkbox" name="isSignificantDay" checked={formData.isSignificantDay} onChange={handleInputChange} disabled={loading} />
                                    Holiday/Significant Day
                                </label>
                            </div>

                            {apps.find(app => app.id === selectedApp)?.hasShiftActiveTime && (
                                <label>
                                    Shift Active Time:
                                    <div className='hours-and-minutes-entry'>
                                        <input
                                            type="number"
                                            min="0"
                                            max="24"
                                            step="1"
                                            name="activeHours"
                                            value={activeHours}
                                            onChange={(e) => setActiveHours(e.target.value)}
                                            disabled={loading}
                                            placeholder='Hours'
                                        />
                                        <input
                                            type="number"
                                            min="0"
                                            max="60"
                                            step="1"
                                            name="activeMinutes"
                                            value={activeMinutes}
                                            onChange={(e) => setActiveMinutes(e.target.value)}
                                            disabled={loading}
                                            placeholder='Minutes'
                                        />
                                    </div>
                                </label>
                            )}
                            <div className="buttons">
                                <button type="button" onClick={goToPreviousStep}>Previous</button>
                                <button type="button" onClick={goToNextStep}>Next</button>
                            </div>
                        </div>
                        <div className='form-step'>
                            <label>
                                Zip Code:
                                <input type="text" name="zipCode" value={formData.zipCode} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            {vehicles.find(vehicle => vehicle.id === selectedVehicle)?.usesGas && (
                                <label>
                                    Gas Price ($/Gallon):
                                    <input type="number" min="0" max="10" step="0.01" name="gasPrice" value={formData.gasPrice} onChange={handleInputChange} disabled={loading} required />
                                </label>
                            )}

                            {vehicles.find(vehicle => vehicle.id === selectedVehicle)?.usesElectricity && (
                                <label>
                                    Electricity Price ($/kWh):
                                    <input type="number" min="0" max="2" step="0.01"name="electricityPrice" value={formData.electricityPrice} onChange={handleInputChange} disabled={loading} required />
                                </label>
                            )}

                            <label>
                                Miles Driven:
                                <input type="number" min="0" max="1000" step="0.1"name="milesDriven" value={formData.milesDriven} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <label>
                                Number of Deliveries:
                                <input type="number" min="0" max="100" step="1"name="numDeliveries" value={formData.numDeliveries} onChange={handleInputChange} disabled={loading} required />
                            </label>
                            <div className="buttons">
                                <button type="button" onClick={goToPreviousStep}>Previous</button>
                                <button type="button" onClick={goToNextStep}>Next</button>
                            </div>
                        </div>
                        <div className='form-step'>
                            <label>
                                Base Pay:
                                <input type="number" min="0" max="10000" step="0.01" name="basePay" value={formData.basePay} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <label>
                                Tips:
                                <input type="number" min="0" max="10000" step="0.01" name="tips" value={formData.tips} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <label>
                                Additional Pay:
                                <input type="number" min="0" max="10000" step="0.01" name="additionalPay" value={formData.additionalPay} onChange={handleInputChange} disabled={loading} required />
                            </label>

                            <label>
                                Additional Notes:
                                <textarea
                                    id="additionalNotes"
                                    name="additionalNotes"
                                    value={formData.additionalNotes}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                />
                            </label>
                            {errorMessage && <p className='error-message'>{errorMessage}</p>}

                            <div className='buttons'>
                                <button type="button" onClick={goToPreviousStep}>Back</button>
                                <button type="submit" disabled={!formIsValid}>Add Shift</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};